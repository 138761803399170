<template>
<div>
  <div class="container-fluid">
    <PageTitle menu="delegasi" />
    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"

        :no-btn-mass="true"
        :no-multi-select="true"
      > 
        <template v-if="moduleRole('isAdministratorSystem') || moduleRole('isAuditor')" #filters>
          <b-col md=3>
            <VSelect 
              v-model="filter.level" 
              placeholder="-- Semua Level --"
              :options="mrLevel" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
          <b-col md=3>
            <VSelect 
              v-model="filter.kantor" 
              placeholder="-- Semua Kantor --"
              :options="mrKantor" 
              :reduce="v=>String(v.value)" 
              @input="doFilter"
            />
          </b-col>
        </template>
        <template #bd_start_date="data">
          {{ data.scope.value | moment('DD MMMM YYYY') }}
        </template>
        <template #bd_end_date="data">
          {{ data.scope.value | moment('DD MMMM YYYY') }}
        </template>
        <template #bd_status="data">
          <LabelStatus :status="data.scope.value" type="delegasi" />
        </template>
        <template #aksi="data">
          <b-button-group>
            <b-button
              variant="warning"
              :to="{
                name: $route.name,
                params: { pageSlug: data.scope.item[idKey] },
              }"
            >
              <div v-b-tooltip.hover="'Ubah Masa Delegasi'">
                <b-icon icon="pencil-square"></b-icon>
              </div>
            </b-button>
            <b-button
              variant="danger"
              @click="doDelete(data.scope.index, data.scope.item)"
            >
              <div v-b-tooltip.hover="'Hapus Delegasi'">
                <b-icon icon="x-octagon-fill"></b-icon>
              </div>
            </b-button>
          </b-button-group>
        </template>
      </card-list>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Level <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.level" 
                    placeholder="-- Pilih Level --"
                    :options="mrLevel" 
                    :reduce="v=>v.value"
                  />
                  <VValidate 
                    name="Level" 
                    v-model="row.level" 
                    rules="required"
                  />
                </b-form-group>
              </b-col>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Kantor <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.kantor" 
                    placeholder="-- Pilih Kantor --"
                    :options="mrKantorPerLevel" 
                    :reduce="v=>v.value"
                  />
                  <VValidate 
                    name="Kantor" 
                    v-model="row.kantor" 
                    rules="required"
                  />
                </b-form-group>
              </b-col>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Yang Didelegasi <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.bd_from_id" 
                    placeholder="-- Pilih User --"
                    :options="FromUser" 
                    :reduce="v=>v.value"
                  />
                  <VValidate 
                    name="Yang Didelegasi" 
                    v-model="row.bd_from_id" 
                    :rules="mrValidation.bd_from_id" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Didelegasikan Ke <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.bd_to_id" 
                    placeholder="-- Pilih User --"
                    :options="ToUser" 
                    :reduce="v=>v.value"
                  />
                  <VValidate 
                    name="Didelegasikan Ke" 
                    v-model="row.bd_to_id" 
                    :rules="mrValidation.bd_to_id" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Tanggal Mulai Delegasi <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-datepicker 
                    v-model="row.bd_start_date"
                    locale="id"
                    :date-format-options="{ year: 'numeric', month: 'long', day: '2-digit', weekday: 'short' }"
                    placeholder="Pilih Tanggal"
                    @input="row.bd_end_date=null"
                    :min="new Date()"
                  />
                  <VValidate 
                    name="Tgl Mulai Delegasi" 
                    v-model="row.bd_start_date" 
                    :rules="mrValidation.bd_start_date" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Tanggal Akhir Delegasi <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-datepicker 
                    v-model="row.bd_end_date"
                    locale="id"
                    :min="row.bd_start_date"
                    :date-format-options="{ year: 'numeric', month: 'long', day: '2-digit', weekday: 'short' }"
                    placeholder="Pilih Tanggal"
                  />
                  <VValidate 
                    name="Tgl Akhir Delegasi" 
                    v-model="row.bd_end_date" 
                    :rules="mrValidation.bd_end_date" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=12>
                <b-form-group>
                  <template #label>
                    Catatan <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-textarea
                    v-model="row.bd_note"
                    rows="5"
                  ></b-form-textarea>
                  <VValidate 
                    name="Catatan" 
                    v-model="row.bd_note" 
                    :rules="mrValidation.bd_note" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
import Gen from '@/libs/Gen'

let _ = global._
export default{
  extends:GlobalVue,
  components:{PageTitle, CardList},
  data(){
    return {
      idKey:'bd_id',
      fields:[
        { key:'number', label:'#' },
        { key:'name_from', label:'Yang didelegasi' },
        { key:'name_to', label:'Didelegasikan Ke-' },
        { key:'bd_start_date', label:'Tanggal Mulai Delegasi', is_custom:true },
        { key:'bd_end_date', label:'Tanggal Berakhir Delegasi', is_custom:true },
        { key:'bd_status', label:'Status', is_custom:true },
        { key:'aksi', is_custom:true, style:{width:'170px'} },
      ],
      mrUser: [],
      mrLevel:[],
      mrKantor:[]
    }
  },
  computed:{
    FromUser(){
      if(!this.row.kantor){
        return this.mrUser
      }else{
        return _.filter(_.clone(this.mrUser), (v)=>{
          return v.office_id == this.row.kantor
        })
      }
    },
    ToUser(){
      let from = _.filter(_.clone(this.mrUser||[]), (v)=>{
        return v.value==this.row.bd_from_id
      });
      from = from.length ? from[0].office_id : ""

      return _.filter(_.clone(this.mrUser||[]), (v)=>{
        return v.value != this.row.bd_from_id && v.office_id==from
      })
    },
    mrKantorPerLevel(){
      if(!this.row.level){
        return this.mrKantor
      }else{
        const level = this.row.level=='divisi'?'pusat':this.row.level
        const mrKantor = _.filter(_.clone(this.mrKantor), (v)=>{
          return v.level == level
        })

        return mrKantor
      }
    }
  },
  methods:{
    forceDelegation(id){
      global.Swal.fire({
        title: `Ingin memberhentikan delegasi?`,
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#0097D8',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya, Berhenti!`,
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 
          // `btn btn-primary
          // ${ status.value =='approved' ? 'btn-success' : status.value=='draft' ? 'btn-warning' :
          //   status.value =='purpose' ? 'btn-info' : status.value == 'rejected' ? 'btn-danger' : status.value=='obsolete' ? 'btn-dark' : 'btn-danger' }`,
          'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(res => {
          if(res.value){
            this.loadingOverlay = true
            this.approvalButtonDisabled = true

            Gen.apiRest(
              "/do/"+this.modulePage,
              {data: {type: "stop-delegation", id: id}},
              "POST"
            )
              .then(()=>{
                this.loadingOverlay = false 
                global.Swal.fire({
                  title: `Berhasil memberhentikan delegasi.`,
                  icon: 'success',
                })
                this.apiGet()
              })
              .catch(()=>{ 
                this.loadingOverlay = false
              })
          }
        })
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'row.bd_from_id'(v,o){
      if(o && v){
        this.$set(this.row,'bd_to_id',null)
      }
    },
    'row.kantor'(v, o){
      if(o && v){
        this.$set(this.row,'bd_from_id',null)
      }
    }
  }
}
</script>